export const LOAD_POSTS_REQUEST = 'LOAD_POSTS_REQUEST'
export const LOAD_POSTS_FAILURE = 'LOAD_POSTS_FAILURE'
export const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'

export const UPSERT_POST_SUCCESS = 'UPSERT_POST_SUCCESS'

export const LOAD_COUNTS_REQUEST = 'LOAD_COUNTS_REQUEST'
export const LOAD_COUNTS_FAILURE = 'LOAD_COUNTS_FAILURE'
export const LOAD_COUNTS_SUCCESS = 'LOAD_COUNTS_SUCCESS'

export const LOAD_COMMENTS_REQUEST = 'LOAD_COMMENTS_REQUEST'
export const LOAD_COMMENTS_FAILURE = 'LOAD_COMMENTS_FAILURE'
export const LOAD_COMMENTS_SUCCESS = 'LOAD_COMMENTS_SUCCESS'
