import axios from 'axios'
import io from 'socket.io-client';

// const apiUrl = 'http://a.p-p-p-p.cn';
const apiUrl = 'http://api.nhpy.art';

axios.defaults.baseURL = apiUrl;
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
  reqConfig => {
    reqConfig.headers.Authorization = 'Bearer wx_oF_e3uGDBXIpC3ivy7be_7Xc2LH8';
    return reqConfig;
  },
  err => Promise.reject(err),
);

window.socket = io(apiUrl);

window.socket.on('connect', function () {
  console.log('socket.io connected')
})



