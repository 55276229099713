import React, { Fragment, Component } from 'react';
import './App.css';
import {Router, Route} from "react-router-dom";
import 'antd/lib/style/index.css'
import Home from './routes/home/Home'
import history from './history'
import Details from './routes/details/Details'

class App extends Component {
  render() {
    return <Router history={ history }>
      <Fragment>
        <Route exact path="/" component={Home} />
        <Route exact path="/details" component={Details} />
      </Fragment>
    </Router>
  }
}

export default App;
