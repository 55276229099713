import React, { Component } from 'react';
import { connect } from 'react-redux'
import './DetailRow.css'
import {formatContent} from "../../utils";
import moment from 'moment'

class DetailRow extends Component {
  state = {hidden: false}

  renderPost(post){
    let {style} = this.props
    const mm = moment(post.createdAt)
    const date = mm.format("YYMMDD hh:mm")
    let user = post.anonymous?'匿名':post.user.nickname
    return <div className={"details-row"} style={{...style}}>
      <div className={"details-row-user"}>{`[${date}] ${user}`}</div>
      <div className={"details-row-content"}>{formatContent(post.content)}</div>
    </div>
  }

  renderComment(comment){
    let {style} = this.props
    let {relatedPost} = this.props
    let username = comment.anonymous?'匿名':comment.user.nickname
    let user = username
    if(relatedPost){
      let relatedUsername = relatedPost.anonymous?'匿名':relatedPost.user.nickname
      user = username + "@" + relatedUsername
    }
    const mm = moment(comment.createdAt)
    const date = mm.format("YYMMDD hh:mm")
    return <div className={"details-row"} style={{...style}}>
      <div className={"details-row-user"}>{`[${date}]  ${user}`}</div>
      <div className={"details-row-content"}>{formatContent(comment.content)}</div>
    </div>
  }

  render() {
    const {post, comment} = this.props
    if(post){
      return this.renderPost(post)
    }else if(comment) {
      return this.renderComment(comment)
    }else{
      return null
    }
  }

}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const mapStateToProps = (state, ownProps) => {
  const {comment} = ownProps
  return {
    relatedPost: comment?state.posts.posts[comment.post]:null,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailRow)


