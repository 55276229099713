import React, { Component } from 'react';
import Measure from 'react-measure'
import { connect } from 'react-redux'
import point from "./images/point.png"
import comment from "./images/comment.png"
import history from './history'
import './PostBox.css'
import {formatContent} from "./utils";

class PostBox extends Component {
  state={width: 0, height: 0}
  render() {
    let offset = 10
    let {height} = this.state
    let {post, x, y, totalWidth, totalHeight, randomW, randomX, randomY} = this.props
    const step = 1.0/20
    randomX = Math.floor(randomX/step)*step
    randomY = Math.floor(randomY/step)*step
    randomW = Math.floor(randomW/step)*step
    const width = totalWidth/5 * (1 + (0.5 - randomW)*0.5)
    x = x < offset + width/2 ? offset*(1+randomX) + width/2 : x
    x = x < totalWidth - width/2 - offset ? x : totalWidth - width/2  - offset*(1+randomX)
    y = y < height/2 + offset ? offset*(1+randomY) + height/2 : y
    y = y < height/2 + totalHeight - offset ? y : totalHeight - height/2 - offset*(1+randomY)
    let zIndex = post.position.z || 1
    return  <Measure
      bounds
      onResize={contentRect => {
        this.setState({ ...contentRect.bounds })
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={`post-box-container`}
          style={{
            width,
            top: y ,
            left: x ,
            zIndex
          }}
        >
          <div
            className={"post-box-header"}
          >
            <span
              className={"post-box-head-image"}
              style={{
                backgroundImage: !post.anonymous && `url(${post.user.headImgUrl})`
              }}
            ></span>
            <span
              className={"post-box-nickname"}
            >
              {post.anonymous?'匿名':post.user.nickname}
            </span>
          </div>
          <div className={"post-box-content"}>
            {formatContent(post.content.length<100?post.content:post.content.slice(0,100)+'...')}
          </div>
          <div className={"post-box-footer"}>
            <span className={"post-box-point"} style={{
              backgroundImage: `url(${point})`
            }}>{post.rewards || 0}</span>
            <span className={"post-box-comment"} style={{
              backgroundImage: `url(${comment})`
            }}>{post.commentsCount || 0}</span>
          </div>
          <div className={"post-box-qrcode"} style={{
            backgroundImage: `url(http://s.nhpy.art/qrcode?width=200&url=http://nhpy.art/posts/${post._id}&foreground=e62a20)`,
            // backgroundImage: `url(http://pmj.mobi/qrcode?width=200&url=http://m.pmj.mobi/posts/${post._id}&foreground=e62a20)`,
          }}
          >
          </div>
        </div>
      )}
    </Measure>
  }

}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostBox)

