import axios from 'axios'
import * as actions from '../constants'

export const watchPosts = () => dispatch=>{
  window.socket.on("upsert", (post)=>{
    console.log("upsert", post)
    dispatch({
      type: actions.UPSERT_POST_SUCCESS,
      payload: post
    })
  })
  window.socket.on("delete", (post)=>{
    console.log("delete", post)
    dispatch({
      type: actions.UPSERT_POST_SUCCESS,
      payload: post
    })
  })
}

export const loadPosts = () => dispatch=>{
  dispatch({
    type: actions.LOAD_POSTS_REQUEST
  })
  axios.get('/posts').then((response)=>{
    if(!response){
      return
    }
    let data = response.data
    dispatch({
      type: actions.LOAD_POSTS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_POSTS_FAILURE,
      payload: (error.response && error.response.data) || error.message
    })
    setTimeout(()=>{
      dispatch(loadPosts())
    }, 10000)
  })
}

export const loadCounts = () => dispatch=>{
  dispatch({
    type: actions.LOAD_COUNTS_REQUEST
  })
  axios.get('/counts').then((response)=>{
    if(!response){
      return
    }
    let data = response.data
    dispatch({
      type: actions.LOAD_COUNTS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_COUNTS_FAILURE,
      payload: (error.response && error.response.data) || error.message
    })
    setTimeout(()=>{
      dispatch(loadCounts())
    }, 10000)
  })
}

export const loadComments = () => dispatch=>{
  dispatch({
    type: actions.LOAD_COMMENTS_REQUEST
  })
  axios.get('/comments').then((response)=>{
    if(!response){
      return
    }
    let data = response.data
    dispatch({
      type: actions.LOAD_COMMENTS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_COMMENTS_FAILURE,
      payload: (error.response && error.response.data) || error.message
    })
    setTimeout(()=>{
      dispatch(loadComments())
    }, 10000)
  })
}
