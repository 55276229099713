import React, { Fragment, Component } from 'react';
import { loadPosts, watchPosts, loadCounts, loadComments } from '../../actions/posts'
import { connect } from 'react-redux'
import {Icon} from 'antd'
import DetailRow from './DetailRow'
import qrcode from '../../images/qrcode.png'
import './Details.css'
const step = Math.floor(900/45)
const interval = 30000

class Details extends Component {
  state = {
    offsetA: 0,
    offsetB: 2*step
  }
  componentDidMount(){
    const self = this
    self.props.watchPosts()
    self.props.loadPosts()
    self.props.loadCounts()
    self.props.loadComments()
    self.ri = setInterval(()=>{
      self.props.loadPosts()
      self.props.loadCounts()
      self.props.loadComments()
      self.setState({
        offsetA: 0,
        offsetB: 2*step
      })
    }, 5*60*1000)
    self.startALoop()
    self.bt = setTimeout(()=>{
      self.startBLoop()
    }, interval/2)
  }

  componentWillUnmount(){
    clearTimeout(this.at)
    clearTimeout(this.bt)
    clearInterval(this.ai)
    clearInterval(this.bi)
    clearInterval(this.ri)
  }

  startBLoop(){
    const self = this
    self.bi = setInterval(()=>{
      self.setState({
        hideB: true,
      })
      self.bt = setTimeout(()=>{
        self.setState({
          offsetB: self.state.offsetB + step
        })
        self.bt = setTimeout(()=>{
          self.setState({
            hideB: false,
          })
        }, 500)
      }, 500)
    }, interval)
  }

  startALoop(){
    const self = this
    self.ai = setInterval(()=>{
      self.setState({
        hideA: true,
      })
      self.at = setTimeout(()=>{
        self.setState({
          offsetA: self.state.offsetA + step
        })
        self.at = setTimeout(()=>{
          self.setState({
            hideA: false,
          })
        }, 500)
      }, 500)
    }, interval)
  }


  renderCommentsA(){
    let {items} = this.props
    let {hideA, offsetA} = this.state
    if(items.length === 0){
      return null
    }
    items = items.slice()
    console.log('A slice before', offsetA, items.length, step)
    offsetA = offsetA % items.length
    if(offsetA + step < items.length){
      items = items.slice(offsetA, offsetA + step)
    }else{
      items = items.slice(offsetA)
    }
    console.log('A slice', offsetA, items.length, step)
    return items.map(item=>(<DetailRow
        style={{
          opacity: hideA?0:1
        }}
        key={item._id}
        post={item.post?null:item}
        comment={item.post?item:null}
      />
    ))
  }

  renderCommentsB(){
    let {items} = this.props
    let {hideB, offsetB} = this.state
    if(items.length === 0){
      return null
    }
    items = items.slice()
    console.log('B slice before', offsetB, items.length, step)
    offsetB = offsetB % items.length
    if(offsetB + step < items.length - 1){
      items = items.slice(offsetB, offsetB + step)
    }else{
      items = items.slice(offsetB)
    }
    console.log('B slice', offsetB, items.length, step)
    return items.map(item=>(<DetailRow
        style={{
          opacity: hideB?0:1
        }}
        key={item._id}
        post={item.post?null:item}
        comment={item.post?item:null}
      />
    ))
  }

  render() {
    const {loadingPosts, loadingPostsError, counts} = this.props
    return <div
      className="home-posts-container"
    >
      {
        loadingPostsError && <span className={'center danger'}>
          {loadingPostsError}
        </span>
      }
      {
        (!loadingPostsError && loadingPosts) && <span className={'center'}>
          <Icon type="loading" className={'loading-icon center'} />
          <span className={'text'}>载入中...</span>
        </span>
      }
      {
        (!loadingPostsError && !loadingPosts) && <Fragment>
          <div className="details-container">
            <div className={"details-half-A"}>
            {this.renderCommentsA()}
            </div>
            <div className={"details-half-B"}>
              {this.renderCommentsB()}
            </div>
            <div className={"details-bg"}>
              <div className={"details-bg-title"}>「你好，朋友」</div>
              <div className={"details-bg-meta"}>
                <span>USERS: {counts.user}</span>
                <span>POSTS: {counts.post}</span>
                <span>COMMENTS: {counts.comment}</span>
                <span>LIKES: {counts.like}</span>
              </div>
            </div>
            <img src={qrcode} className={"details-qrcode"}/>
            <div className={"details-meta"}>
              <div>
                <span>参与数量: </span>
                <span>{counts.user}</span>
              </div>
              <div>
                <span>问题数量:</span>
                <span>{counts.post}</span>
              </div>
              <div>
                <span>评论数量:</span>
                <span>{counts.comment}</span>
              </div>
              <div>
                <span>点赞数量: </span>
                <span>{counts.like}</span>
              </div>
            </div>
          </div>
        </Fragment>
      }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  let posts = state.posts.rawPosts.slice()
  let comments = state.posts.rawComments.slice()
  let items = []
  while(posts.length > 0 || comments.length > 0){
    if(posts.length === 0){
      items.push(comments.shift())
    }else if(comments.length === 0){
      items.push(posts.shift())
    }else if(posts[0].createdAt > comments[0].createdAt){
      items.push(comments.shift())
    }else{
      items.push(posts.shift())
    }
  }
  return {
    posts: state.posts.posts,
    counts: state.posts.counts,
    items,
    loadingPosts: state.posts.loadingPosts,
    loadingPostsError: state.posts.loadingPostsError
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadPosts: ()=>dispatch(loadPosts()),
    watchPosts: ()=>dispatch(watchPosts()),
    loadCounts: ()=>dispatch(loadCounts()),
    loadComments: ()=>dispatch(loadComments())
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Details)

