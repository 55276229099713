import * as actions from '../constants'
import {random} from "../utils";

const inititalState = {
  loadingPosts: true,
  loadingPostsError: null,
  loadingComments: true,
  loadingCommentsError: null,
  loadingCounts: true,
  loadingCountsError: null,
  zIndex: 1,
  posts: {},
  comments: {},
  rawPosts: [],
  rawComments: [],
  counts: {
    user: 0,
    like: 0,
    post: 0,
    comment: 0
  }
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.UPSERT_POST_SUCCESS:
    {
      let posts = {...state.posts}
      let post = {...action.payload}
      let zIndex = state.zIndex + 1
      if(posts[post._id]){
        post.position = posts[post._id].position
        console.log(post.position)
        post.position = {
          x: post.position.x + (Math.random() - 0.5)*0.5,
          y: post.position.y + (Math.random() - 0.5)*0.5,
          z: zIndex,
          w: post.position.w + (Math.random() - 0.5)*0.1,
        }
        console.log(post.position)
        posts[post._id] = post
      }else{
        post.position = {
          x: Math.random(),
          y: Math.random(),
          z: zIndex,
          w: Math.random()
        }
        posts[post._id] = post
      }
      return {
        ...state,
        zIndex,
        posts,
      }
    }
    case actions.LOAD_POSTS_REQUEST:
      return {
        ...state,
        zIndex: 1,
        posts: {},
        loadingPosts: true,
        loadingPostsError: null
      }
    case actions.LOAD_POSTS_SUCCESS:
    {
      let posts = {...state.posts}
      action.payload.forEach(p=>{
        let post = {...p}
        post.position = {
          x: Math.random(),
          y: Math.random(),
          z: 1,
          w: Math.random()
        }
        posts[post._id] = post
      })
      return {
        ...state,
        posts,
        rawPosts: action.payload,
        zIndex: 1,
        loadingPosts: false,
        loadingPostsError: null
      }
    }
    case actions.LOAD_POSTS_FAILURE:
      return {
        ...state,
        loadingPosts: false,
        loadingPostsError: action.payload
      }
    case actions.DELETE_POST_SUCCESS:
    {
      if(action.payload && action.payload._id){
        let posts = {...state.posts}
        delete posts[action.payload._id]
        return {
          ...state,
          posts
        }
      }else{
        return state
      }
    }
    case actions.LOAD_COUNTS_REQUEST:
    {
      return {
        ...state,
        loadingCounts: true,
        loadingCountsError: null
      }
    }
    case actions.LOAD_COUNTS_SUCCESS:
    {
      return {
        ...state,
        loadingCounts: false,
        loadingCountsError: null,
        counts: {
          ...action.payload
        }
      }
    }
    case actions.LOAD_COUNTS_FAILURE:
    {
      return {
        ...state,
        loadingCounts: false,
        loadingCountsError: action.payload,
      }
    }
    case actions.LOAD_COMMENTS_REQUEST:
      return {
        ...state,
        zIndex: 1,
        posts: {},
        loadingComments: true,
        loadingCommentsError: null
      }
    case actions.LOAD_COMMENTS_SUCCESS:
    {
      let comments = {...state.comments}
      action.payload.forEach(p=>{
        let comment = {...p}
        comment.position = {
          x: Math.random(),
          y: Math.random(),
          z: 1,
          w: Math.random()
        }
        comments[comment._id] = comment
      })
      return {
        ...state,
        comments,
        rawComments: action.payload,
        zIndex: 1,
        loadingComments: false,
        loadingCommentsError: null
      }
    }
    case actions.LOAD_COMMENTS_FAILURE:
      return {
        ...state,
        loadingComments: false,
        loadingCommentsError: action.payload
      }
    default:
      return state
  }
}
