import React, { Fragment, Component } from 'react';
import { loadPosts, watchPosts } from '../../actions/posts'
import { connect } from 'react-redux'
import {Icon} from 'antd'
import PostBox from '../../PostBox'
import withSizes from 'react-sizes'
import './Home.css'

class Home extends Component {

  componentDidMount(){
    const self = this
    self.props.watchPosts()
    self.props.loadPosts()
    setInterval(()=>{
      self.props.loadPosts()
    }, 5*60*1000)
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }


  renderMessages(){
    let {posts, width, height, randomXs, randomYs, randomWs} = this.props
    let keys = Object.keys(posts)
    // keys = this.shuffle(keys.slice())
    if(keys.length>300){
      keys = keys.slice(0, 300)
    }
    console.log(keys.length)
    let selectKeys = keys
    return selectKeys.map(key=>{
      let post = posts[key]
      let ratio = 0.75
      // if(index >= 49){
      //   ratio = 0.2
      // }
      let randomX = post.position.x || 0
      let randomY = post.position.y || 0
      let randomW = post.position.w || 0
      let x = 0.5*width + ratio*width*(0.5 - randomX)
      const y = 0.5*height + ratio*height*(0.5 - randomY)
      return <PostBox
          x={x}
          y={y}
          randomX={randomX}
          randomY={randomY}
          randomW={randomW}
          post={post}
          key={key}
          totalWidth={width}
          totalHeight={height}
        />
    })
  }

  render() {
    const {loadingPosts, loadingPostsError} = this.props
    const self = this
    return <div
      className="home-posts-container"
    >
      {
        loadingPostsError && <span className={'center danger'}>
          {loadingPostsError}
        </span>
      }
      {
        (!loadingPostsError && loadingPosts) && <span className={'center'}>
          <Icon type="loading" className={'loading-icon center'} />
          <span className={'text'}>载入中...</span>
        </span>
      }
      {
        (!loadingPostsError && !loadingPosts) && <Fragment>
          <div className="home-posts-container">
            {this.renderMessages()}
          </div>
        </Fragment>
      }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    posts: state.posts.posts,
    randomXs: state.posts.randomXs,
    randomYs: state.posts.randomYs,
    randomWs: state.posts.randomWs,
    loadingPosts: state.posts.loadingPosts,
    loadingPostsError: state.posts.loadingPostsError
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadPosts: ()=>dispatch(loadPosts()),
    watchPosts: ()=>dispatch(watchPosts())
  }
}

const mapSizesToProps = ({ width, height }) => {
  return {width, height}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(Home))

